<template>
  <p v-b-popover.hover.top="fullDate">{{ prettyDate }}</p>
</template>

<script>
import momentMixin from '../mixins/momentMixin'

export default {
  mixins: [momentMixin],
  computed: {
    prettyDate () {
      return this.notRelative ? this.moment(this.date).format(this.timeOnly ? 'HH:mm' : 'DD/MM/YYYY HH:mm') : this.moment(this.date).fromNow()
    },
    fullDate () {
      return this.moment(this.date).format('Do MMMM YYYY HH:mm:ss')
    }
  },
  props: {
    date: {
      required: true,
      type: String
    },
    notRelative: {
      default: false,
      type: Boolean
    },
    timeOnly: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style>

</style>
